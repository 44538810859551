<template>
    <div>
      <div class='main-loader' v-if='isLoading'></div>
      <div class="product-page">
        <b-container>
          <div v-if="likedImages.length">
            <b-row>
              <product-card 
                v-for="product in likedImages" 
                :key="product.id" 
                :product="product" 
                @toggle-like="updateLikeStatus" 
              />
            </b-row>
          </div>
          <div class="no-data mt-5 mb-5" v-else-if="!isLoading && !likedImages.length">
            <div class="mx-auto text-center">
              <h2>No liked images found</h2>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </template>
  
  <script>
  import productCard from './imageCard.vue';
  
  export default {
    components: {
      productCard,
    },
    data() {
      return {
        isLoading: false,
        likedImages: [],
        noDataMessage: 'No liked images found.',
      };
    },
    mounted() {
      this.fetchLikedImages();
    },
    created() {
    this.checkUserLoggedIn();
    },
    methods: {
      checkUserLoggedIn() {
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null

            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
        },  
        
      async fetchLikedImages() {
        const user = JSON.parse(localStorage.getItem('userdetails'));
        const userId = user?.user_id;
  
        if (!userId) {
          // console.error('User ID is not available in local storage');
          return;
        }
  
        this.isLoading = true;
  
        try {
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}getLikedImages?userId=${userId}`);
          const data = await response.json();
  
          if (Array.isArray(data.images)) {
            this.likedImages = data.images.map(image => ({
              id: image.id,
              image_url: image.imageUrl,
              prompt: image.prompt,
              like_or_dislike: image.like_or_dislike,
              timestamp: image.timestamp
            }));
          } else {
            console.error('Expected an array of liked images, but got:', data.images);
          }
        } catch (error) {
          console.error('Error fetching liked images:', error);
        } finally {
          this.isLoading = false;
        }
      },
      async updateLikeStatus(imageId, likeStatus) {
        const user = JSON.parse(localStorage.getItem('userdetails'));
        const userId = user?.user_id;
  
        if (!userId) {
          console.error('User ID is not available in local storage');
          return;
        }
  
        try {
          const response = await fetch('https://api.briks.ai/api/updateLikeStatus', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, imageId, likeStatus })
          });
  
          const data = await response.json();
  
          if (data.message === 'Like status updated successfully!') {
            const image = this.likedImages.find(img => img.id === imageId);
            if (image) {
              image.like_or_dislike = likeStatus;
              if (likeStatus === 'N') {
                this.likedImages = this.likedImages.filter(img => img.id !== imageId);
              }
            }
          }
        } catch (error) {
          console.error('Error updating like status:', error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .search-input {
    border-radius: 0rem !important;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  
  .swiper-slide {
    display: flex;
    align-items: 'auto';
    justify-content: center;
    padding: 2px !important;
    padding-bottom: 15px !important;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  </style>
  