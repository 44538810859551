<template>
  <b-col cols="12" md="4" lg="3">
    <b-card class="product-card d-flex flex-column" no-body>
      <div class="position-relative image-container">
        <b-img fluid class="card-img-top product-list-img-object-fit" :src="computedImageUrl" @click="showModal = true" />
        <div class="position-absolute top-right-icons">
          <div 
            v-if="showLikeButton"
            class="top-right-icon love-icon"
            :class="product.like_or_dislike === 'Y' ? 'filled-heart' : 'empty-heart'"
            @click="toggleLike">
            <feather-icon size="24" icon="HeartIcon" :fill="product.like_or_dislike === 'Y' ? 'red' : 'none'" />
          </div>
          <div 
            v-if="showShareButton" 
            class="top-right-icon share-icon"
            @click="toggleShareOptions">
            <feather-icon size="24" icon="Share2Icon" />
          </div>
          <div v-if="showShareOptions" class="share-options">
            <div class="top-right-icon share-icon" @click="shareImage('web')">
              <feather-icon size="24" icon="GlobeIcon" />
            </div>
            <div class="top-right-icon share-icon" @click="shareImage('linkedin')">
              <feather-icon size="24" icon="LinkedinIcon" />
            </div>
            <div class="top-right-icon share-icon" @click="shareImage('whatsapp')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M21 11.5a8.37 8.37 0 0 1-.9 3.8 8.49 8.49 0 0 1-7.6 4.7 8.37 8.37 0 0 1-3.8-.9L3 21l1.9-5.7a8.37 8.37 0 0 1-.9-3.8 8.49 8.49 0 0 1 4.7-7.6 8.37 8.37 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8Z"/>
                <path d="M9.49 10a7.58 7.58 0 0 0 .72 1.42A8 8 0 0 0 14 14.5M9.49 10a7.47 7.47 0 0 1-.4-1.4.51.51 0 0 1 .52-.6h0a.54.54 0 0 1 .51.37l.38 1.13ZM14 14.5a7.8 7.8 0 0 0 1.43.41.51.51 0 0 0 .6-.52h0a.54.54 0 0 0-.37-.51l-1.16-.38Z"/>
              </svg>
            </div>
            <div class="top-right-icon share-icon" @click="shareImage('twitter')">
              <feather-icon size="24" icon="TwitterIcon" />
            </div>
            <div class="top-right-icon share-icon" @click="shareImage('facebook')">
              <feather-icon size="24" icon="FacebookIcon" />
            </div>
          </div>
        </div>
      </div>
      <b-card-body class="d-flex flex-column p-0">
        <b-button block class="cart-btn mt-auto no-border-radius" @click="downloadImage(computedImageUrl)" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
          <feather-icon icon="ArrowDownCircleIcon" class="mr-25" />
          Download
        </b-button>
      </b-card-body>

      <!-- Image Modal -->
      <b-modal v-model="showModal" hide-header hide-footer centered size="med" no-close-on-backdrop backdrop-dismissable @hidden="showModal = false" content-class="p-0" dialog-class="modal-90w modal-no-margin" body-class="p-0">
        <div class="modal-close-container">
          <button type="button" class="close clear-button" @click="showModal = false">&times;</button>
        </div>
        <img :src="computedImageUrl" class="w-100 h-auto" />
      </b-modal>
    </b-card>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive';


export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    showLikeButton: {
      type: Boolean,
      default: true,
    },
    showShareButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      showShareOptions: false,
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    computedImageUrl() {
      return this.product.image_url || this.product.imageUrl || this.image.imageUrl  ;
    }
  },
  methods: {
  async downloadImage(url) {
    const user = JSON.parse(localStorage.getItem('userdetails'));
    const userId = user?.user_id;

    if (!userId) {
      // Show the login modal and wait for the login to complete
      const loginSuccess = await this.showLoginModal();
      if (!loginSuccess) {
        return;
      }
    }

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split('/').pop();
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Remove the link after triggering the download
      window.URL.revokeObjectURL(link.href); // Free up memory used by the blob
    } catch (error) {
      console.error('Error downloading the image', error);
    }
  },
  showLoginModal() {
    return new Promise((resolve) => {
      this.$bvModal.show('modal-login');

      // Listen for the login event
      const handleLoginSuccess = () => {
        resolve(true);
        cleanup();
      };

      const handleLoginFailure = () => {
        resolve(false);
        cleanup();
      };

      const cleanup = () => {
        this.$root.$off('login-success', handleLoginSuccess);
        this.$root.$off('login-failure', handleLoginFailure);
      };

      this.$root.$on('login-success', handleLoginSuccess);
      this.$root.$on('login-failure', handleLoginFailure);
    });
  },
  toggleLike() {
    const newStatus = this.product.like_or_dislike === 'Y' ? 'N' : 'Y';
    this.$emit('toggle-like', this.product.id, newStatus);
  },
  toggleShareOptions() {
    this.showShareOptions = !this.showShareOptions;
  },
  async shareImage(platform) {
    const url = this.computedImageUrl;
    const text = 'See what I found in briks.ai';

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split('/').pop();
      const file = new File([blob], fileName, { type: blob.type });

      if (navigator.share) {
        await navigator.share({
          title: 'Check out this image',
          text: text,
          files: [file],
        });
        console.log('Image shared successfully');
      } else {
        this.shareFallback(platform, text, url);
      }
    } catch (error) {
      console.error('Error fetching the image', error);
      this.shareFallback(platform, text, url);
    }
  },
  shareFallback(platform, text, url) {
    let shareUrl = '';

    switch (platform) {
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(text + ' ' + url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
        break;
      case 'web':
        navigator.clipboard.writeText(url)
          .then(() => {
            alert('Link copied to clipboard!');
          })
          .catch((error) => {
            console.error('Error copying to clipboard:', error);
          });
        return;
      default:
        console.error('Unsupported platform:', platform);
        return;
    }

    window.open(shareUrl, '_blank');
  },
},
};
</script>

<style scoped>
.image-container {
  height: auto; 
  overflow: hidden;
}
.card-img-top {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.position-relative {
  position: relative;
}
.top-right-icons {
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
}
.top-right-icon {
  margin-bottom: 5px;
  cursor: pointer;
}
.share-options {
  display: flex;
  flex-direction: column;
}
.cart-btn {
  margin: 0;
  padding: 10px;
}
.no-border-radius {
  border-radius: 0 !important;
}
.love-icon, .share-icon {
  width: auto;
  height: auto;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 55%;
}
.filled-heart {
  color: red !important;
}
.empty-heart {
  color: rgb(0, 0, 0) !important;
}
.modal-90w {
  max-width: 90%;
}
.modal-no-margin .modal-dialog {
  margin: 0 !important;
}
.modal-no-margin .modal-content {
  border: none !important;
}
.modal-close-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1051; /* Ensure the close button is on top of the image */
}
.clear-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.8;
}
.clear-button:hover {
  opacity: 1;
}

.card {
  margin-bottom: 1rem !important;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
</style>
